<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Send message</span>
        <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="message"
          dense
          label="Type a message"
          auto-grow
          rows="1"
        />
        <v-btn :loading="loading" color="primary" @click="onSendMessage">
          Send
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
    },
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      message: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      sendFilteredMessage: "chat/sendFilteredMessage",
    }),
    onSendMessage() {
      var key = this.onGetKey();
      this.search = key;
      var params = {
        ...JSON.parse(key),
        text: this.message,
        content_type: "text",
      };
      this.loading = true;
      this.sendFilteredMessage(params)
        .then(() => {
          this.loading = false;
          this.onCloseDialog();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    onGetKey() {
      const filterOptions = { ...this.$route.query };
      var obj = {};
      if (filterOptions.new == "true" || filterOptions.new == true)
        obj.is_new = true;
      if (this.keyword) obj.key = this.keyword;

      if (filterOptions.language) {
        obj.language = filterOptions.language;
      }

      if (filterOptions.marital) {
        obj.marital = filterOptions.marital;
      }

      if (filterOptions.english_proficiency)
        obj.english_proficiency = filterOptions.english_proficiency;

      if (filterOptions.access_to_food)
        obj.access_to_foods = filterOptions.access_to_food;

      if (filterOptions.meals_challenge)
        obj.meals_challenge = filterOptions.meals_challenge;

      if (filterOptions.health_rate)
        obj.health_rate = filterOptions.health_rate;

      if (filterOptions.access_to_heat)
        obj.access_to_heat = filterOptions.access_to_heat;

      if (filterOptions.transportation)
        obj.transportation = filterOptions.transportation;

      if (filterOptions.housing) obj.housing = this.filterOptions.housing;

      if (filterOptions.housing_type)
        obj.housing_type = filterOptions.housing_type;

      if (filterOptions.internet) obj.internet = filterOptions.internet;

      if (filterOptions.have_transportation)
        obj.have_transportation = filterOptions.have_transportation;

      if (filterOptions.food_insecurity)
        obj.food_insecurity = filterOptions.food_insecurity;

      if (filterOptions.financial_insecurity)
        obj.financial_insecurity = filterOptions.financial_insecurity;

      if (filterOptions.min_day) obj.min_day = filterOptions.min_day;
      if (filterOptions.max_day) obj.max_day = filterOptions.max_day;
      if (filterOptions.min_month) obj.min_month = filterOptions.min_month;
      if (filterOptions.max_month) obj.max_month = filterOptions.max_month;
      if (filterOptions.min_total_family)
        obj.min_total_family = filterOptions.min_total_family;
      if (filterOptions.max_total_family)
        obj.max_total_family = filterOptions.max_total_family;

      if (filterOptions.family) {
        obj.family_query = JSON.parse(filterOptions.family).query;
      }

      return JSON.stringify(obj);
    },
  },
  computed: {
    ...mapGetters({
      keyword: "auth/getKeyword",
    }),
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
