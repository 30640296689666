<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card style="overflow: auto">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Quick Checked In</span>
        <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          hide-details
          label="Search"
          solo
          dense
          rounded
          outlined
          append-icon="mdi-magnify"
          v-model="keyword"
          @input="onChange"
          ref="search"
        />
      </v-card-text>
      <v-card-text style="height: 270px; min-height: 270px">
        <div v-for="(item, index) in data[keyword]" :key="index">
          <div>
            <div
              class="d-flex flex-row hover-item px-5 justify-between"
              @click="goToCheckedIn(item)"
            >
              <div class="d-flex flex-row">
                <EditableAvatar
                  :image="item.thumbnail ? item.thumbnail : item.image"
                  :size="40"
                  :editable="false"
                />
                <div class="ml-6 mb-3">
                  <div
                    class="app-bold-font"
                    v-html="getFiltered(`${item.first_name} ${item.last_name}`)"
                  />
                  <div v-html="getFiltered(item.client_id)" />
                  <div v-html="getFiltered(item.email)" />
                  <div v-html="getFiltered(formatPhoneNumber(item.phone))" />
                </div>
              </div>
              <div class="mt-3">
                <v-icon @click.stop="onCheckedIn(item)"
                  >mdi-check-circle</v-icon
                >
                <v-icon class="ml-3">mdi-arrow-right</v-icon>
              </div>
            </div>
            <div>
              <td class="px-0" style="width: 836px">
                <v-data-table
                  :headers="subHeaders"
                  :items="item.family"
                  hide-default-footer
                  class="px-3"
                >
                  <template v-slot:[`item.check`]="{ item }">
                    <v-checkbox v-model="item.checked" />
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <div v-html="getFiltered(item.name)" />
                  </template>
                  <template v-slot:[`item.gender`]="{ item }">
                    <div v-html="getFiltered(item.gender)" />
                  </template>
                  <template v-slot:[`item.age`]="{ item }">
                    <div>
                      <div v-html="getAge(item.birthday)" />
                    </div>
                  </template>
                  <template v-slot:[`item.relation`]="{ item }">
                    <div v-html="getFiltered(item.relation)" />
                  </template>
                  <template v-slot:[`item.situation`]="{ item }">
                    <div v-html="getFiltered(item.situation)" />
                  </template>
                  <template v-slot:[`item.schoolName`]="{ item }">
                    <div v-html="getFiltered(item.schoolName)" />
                  </template>
                </v-data-table>
              </td>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      keyword: "",
      data: {},
      loading: false,
      subHeaders: [
        { text: "", value: "check" },
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Relation", value: "relation" },
        { text: "Situation", value: "situation" },
        { text: "School Name", value: "schoolName" },
      ],
      snackbar: false,
      snackbarMessage: null,
    };
  },
  methods: {
    ...mapActions({
      checkedInSearch: "client/checkedInSearch",
      addCheckedIn: "checkedin/addCheckedIn",
    }),
    onChange() {
      if (this.keyword && !this.data[this.keyword]) {
        this.checkedInSearch({ keyword: this.keyword })
          .then((res) => {
            res = res.map((item) => ({
              ...item,
              family: item.family.map((f) => ({ ...f, checked: true })),
            }));

            this.data = { ...this.data, [this.keyword]: res };
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    goToCheckedIn(item) {
      console.log(item);
      if (this.$route.name != "checked-ins") {
        let routeData = this.$router.resolve({
          name: "checked-ins",
          params: { id: item._id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    onCheckedIn(item) {
      let selectedFamily = [];
      for (let i = 0; i < item.family.length; i++) {
        if (item.family[i].checked) {
          selectedFamily.push(item.family[i]);
        }
      }
      if (selectedFamily.length == 0) {
        this.showSnackbar("Please select at least one visitor.");
        return;
      }
      this.addCheckedIn({
        families: selectedFamily,
        client_id: item._id,
        date: new Date(),
      })
        .then((res) => {
          console.log(res);
          this.keyword = "";
          this.$refs.search.$refs.input.focus();
          this.showSnackbar("Checked in successfully!");
        })
        .catch((error) => {
          if (error.response) {
            this.showSnackbar(error.response.data.message);
          } else {
            this.showSnackbar(error);
          }
        });
    },
    getFiltered(words) {
      if (!words) return "";
      var filtered = this.$options.filters.highlight(words, this.keyword);
      return filtered;
    },
    getAge(value) {
      var today = new Date();
      var birthDate = new Date(value);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (isNaN(age)) return "";
      return age;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.snackbarMessage = message;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.search.$refs.input.focus();
    }, 0.5);
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}
.filteredClass {
  background: lightyellow;
  width: fit-content;
}
/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
