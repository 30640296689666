<template>
  <div class="left-section">
    <div style="width: 360px; max-width: 360px;">
      <div>
        <v-card-text class="pt-4 pb-3 d-flex align-center justify-between">
          <label class="client_title ml-4">Filter</label>
          <div class="d-flex align-center">
            <v-btn color="primary" x-small class="ml-2" @click="onSaveFilter"
              >Save filter</v-btn
            >
            <v-btn
              color="primary"
              outlined
              x-small
              class="ml-2"
              @click="onLoadFilter"
              >Load filter</v-btn
            >
            <v-btn
              v-if="onClosePanel"
              icon
              @click="onClosePanel"
              small
              class="ml-2"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-text>
        <v-divider />
        <v-card-text v-if="filterTitle" class="pb-0 px-10 app-bold-font">
          {{ filterTitle }}
        </v-card-text>
        <v-card-text class="align-center">
          <div v-for="section in filters" :key="section.label">
            <div class="mt-3 app-medium-font">
              {{ section.label }}
            </div>
            <div v-if="section.label == 'FAMILY MEMBER' && memberTag">
              <v-chip class="ma-2" close @click:close="clearMemberTag">
                {{ memberTag }}
              </v-chip>
              <div class="mx-3">
                <v-btn small class="mb-1" block @click="addMemberTag">
                  <v-icon size="16">mdi-plus</v-icon>
                  Add
                </v-btn>
              </div>
            </div>
            <div v-else-if="section.label == 'CHECKED IN' && checkedInTag">
              <v-chip class="ma-2" close @click:close="clearCheckedInTag">
                {{ checkedInTag }}
              </v-chip>
              <div class="mx-3">
                <v-btn small class="mb-1" block @click="addCheckedInTag">
                  <v-icon size="16">mdi-plus</v-icon>
                  Add
                </v-btn>
              </div>
            </div>
            <v-expansion-panels accordion multiple flat>
              <v-expansion-panel
                v-for="item in section.children"
                :key="item.label"
              >
                <v-expansion-panel-header class="py-0">
                  {{ item.label }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-if="item.label == 'Age' && section.label == 'FAMILY MEMBER'"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="min_age"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="max_age"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Gender'"
                  class="pb-2"
                >
                  <v-radio-group
                    v-model="gender"
                    hide-details
                    class="mt-0"
                    @change="onChangeMemberFilter"
                    v-if="section.label == 'FAMILY MEMBER'"
                  >
                    <v-radio
                      v-for="gender in genderOptions"
                      :key="gender"
                      :label="gender"
                      :value="gender"
                    ></v-radio>
                  </v-radio-group>
                  <v-radio-group
                    v-model="checked_in_gender"
                    hide-details
                    class="mt-0"
                    @change="onChangeCheckedInFilter"
                    v-else-if="section.label == 'CHECKED IN'"
                  >
                    <v-radio
                      v-for="gender in genderOptions"
                      :key="gender"
                      :label="gender"
                      :value="gender"
                    ></v-radio>
                  </v-radio-group>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Language'"
                  class="pb-2"
                >
                  <v-select
                    :items="languageOptions"
                    outlined
                    dense
                    multiple
                    v-model="languages"
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Relation'"
                  class="pb-2"
                >
                  <v-select
                    :items="relationOptions"
                    outlined
                    dense
                    v-model="relations"
                    multiple
                    @input="onChangeMemberFilter"
                    hide-details
                    v-if="section.label == 'FAMILY MEMBER'"
                  />
                  <v-select
                    :items="relationOptions"
                    outlined
                    dense
                    v-model="checked_in_relations"
                    multiple
                    @input="onChangeCheckedInFilter"
                    hide-details
                    v-else-if="section.label == 'CHECKED IN'"
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Situation'"
                  class="pb-2"
                >
                  <v-select
                    :items="situationOptions"
                    outlined
                    dense
                    v-model="situations"
                    multiple
                    @input="onChangeMemberFilter"
                    hide-details
                    v-if="section.label == 'FAMILY MEMBER'"
                  />
                  <v-select
                    :items="situationOptions"
                    outlined
                    dense
                    v-model="checked_in_situations"
                    multiple
                    @input="onChangeCheckedInFilter"
                    hide-details
                    v-else-if="section.label == 'CHECKED IN'"
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'School'"
                  class="pb-2"
                >
                  <v-select
                    :items="schoolOptions"
                    outlined
                    dense
                    v-model="schools"
                    multiple
                    @input="onChangeMemberFilter"
                    hide-details
                    v-if="section.label == 'FAMILY MEMBER'"
                  />
                  <v-select
                    :items="schoolOptions"
                    outlined
                    dense
                    v-model="checked_in_schools"
                    multiple
                    @input="onChangeCheckedInFilter"
                    hide-details
                    v-else-if="section.label == 'CHECKED IN'"
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Medical'"
                  class="pb-2"
                >
                  <div class="mt-2 font-12 d-flex justify-between align-center">
                    <div>Is pregnant or nursing</div>
                    <v-switch
                      v-model="is_pregnant"
                      hide-details
                      class="mt-0"
                      @change="onChangeMemberFilter"
                    />
                  </div>
                  <div class="mt-2 font-12">
                    Height
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_height"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_height"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    Weight
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_weight"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_weight"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    BMI
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_bmi"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_bmi"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    Total Cholesterol
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_total_cholesterol"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_total_cholesterol"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    HDL (Good)
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_hdl"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_hdl"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    Blood Pressure (Systolic)
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_systolic"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_systolic"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2">
                    Blood Pressure (Diastolic)
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_diastolic"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_diastolic"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                  <div class="mt-2 font-12">
                    Blood Sugar (A1C)
                  </div>
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      v-model="min_blood_sugar"
                      label="Min"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                      class="mr-2"
                    />
                    -
                    <v-text-field
                      outlined
                      dense
                      label="Max"
                      v-model="max_blood_sugar"
                      class="ml-2"
                      @input="onChangeMemberFilter"
                      @keypress.enter="onChangeMemberFilter"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Marital Status'"
                  class="pb-2"
                >
                  <v-select
                    :items="maritalOptions"
                    outlined
                    dense
                    v-model="maritals"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'English proficiency'"
                  class="pb-2"
                >
                  <v-select
                    :items="englishProficiencyOptions"
                    outlined
                    dense
                    v-model="english_proficiencies"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Access to Food'"
                  class="pb-2"
                >
                  <v-select
                    :items="accessFoodOptions"
                    outlined
                    dense
                    v-model="access_to_foods"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Meals you have challenges preparing'"
                  class="pb-2"
                >
                  <v-select
                    :items="mealsChallengeOptions"
                    outlined
                    dense
                    v-model="meals_challenges"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Health rate'"
                  class="pb-2"
                >
                  <v-select
                    :items="healthRateOptions"
                    outlined
                    dense
                    v-model="health_rates"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Access to Heat'"
                  class="pb-2"
                >
                  <v-select
                    :items="accessHeatOptions"
                    outlined
                    dense
                    v-model="access_to_heats"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Transportation'"
                  class="pb-2"
                >
                  <v-select
                    :items="transportationOptions"
                    outlined
                    dense
                    v-model="transportations"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Housing'"
                  class="pb-2"
                >
                  <v-select
                    :items="housingOptions"
                    outlined
                    dense
                    v-model="housings"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Housing type'"
                  class="pb-2"
                >
                  <v-select
                    :items="housingTypeOptions"
                    outlined
                    dense
                    v-model="housing_types"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Internet'"
                  class="pb-2"
                >
                  <v-select
                    :items="yesNoOptions"
                    outlined
                    dense
                    v-model="internets"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Have transportation'"
                  class="pb-2"
                >
                  <v-select
                    :items="yesNoOptions"
                    outlined
                    dense
                    v-model="have_transportations"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Food insecurity'"
                  class="pb-2"
                >
                  <v-select
                    :items="yesNoOptions"
                    outlined
                    dense
                    v-model="food_insecurities"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Financial insecurity'"
                  class="pb-2"
                >
                  <v-select
                    :items="yesNoOptions"
                    outlined
                    dense
                    v-model="financial_insecurities"
                    multiple
                    @input="onChangeFilter"
                    hide-details
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Needed days in a month'"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="min_day"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="max_day"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-if="item.label == 'Needed months'">
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="min_month"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="max_month"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'The number of people in the family'"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="min_total_family"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="max_total_family"
                      @input="onChangeFilter"
                      @keypress.enter="onChangeFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-if="item.label == 'Name'">
                  <v-text-field
                    outlined
                    dense
                    v-model="name"
                    @input="onChangeMemberFilter"
                    @keypress.enter="onChangeMemberFilter"
                    hide-details
                    v-if="section.label == 'FAMILY MEMBER'"
                  />
                  <v-text-field
                    outlined
                    dense
                    v-model="checked_in_name"
                    @input="onChangeCheckedInFilter"
                    @keypress.enter="onChangeCheckedInFilter"
                    hide-details
                    v-else-if="section.label == 'CHECKED IN'"
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content v-if="item.label == 'Note'">
                  <v-text-field
                    outlined
                    dense
                    v-model="checked_in_note"
                    @input="onChangeCheckedInFilter"
                    @keypress.enter="onChangeCheckedInFilter"
                    hide-details
                    v-if="section.label == 'CHECKED IN'"
                  />
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Visited family'"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="checked_in_min_family_number"
                      @input="onChangeCheckedInFilter"
                      @keypress.enter="onChangeCheckedInFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="checked_in_max_family_number"
                      @input="onChangeCheckedInFilter"
                      @keypress.enter="onChangeCheckedInFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Date'"
                  class="d-flex align-center"
                >
                  <div class="d-flex align-center">
                    <div>
                      <v-menu
                        ref="checkedInFromDateTimer"
                        v-model="checkedInFromDateTimer"
                        :close-on-content-click="false"
                        :return-value.sync="checkedInFromDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="checked_in_from_date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            background-color="white"
                            hide-details
                            style="max-width: 105px"
                          />
                        </template>
                        <v-date-picker
                          v-model="checkedInFromDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="checkedInFromDateTimer = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="onSaveFromDate(checkedInFromDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <span class="ml-3">-</span>
                    <div class="ml-3">
                      <v-menu
                        ref="checkedInToDateTimer"
                        v-model="checkedInToDateTimer"
                        :close-on-content-click="false"
                        :return-value.sync="checkedInToDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="checked_in_to_date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            background-color="white"
                            hide-details
                            style="max-width: 105px"
                          />
                        </template>
                        <v-date-picker
                          v-model="checkedInToDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="checkedInToDateTimer = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="onSaveToDate(checkedInToDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content
                  v-if="item.label == 'Age' && section.label == 'CHECKED IN'"
                >
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      v-model="checked_in_min_age"
                      @input="onChangeCheckedInFilter"
                      @keypress.enter="onChangeCheckedInFilter"
                      type="number"
                      hide-details
                    />
                    <span class="ml-3">-</span>
                    <v-text-field
                      outlined
                      dense
                      style="max-width: 80px"
                      class="ml-3"
                      v-model="checked_in_max_age"
                      @input="onChangeCheckedInFilter"
                      @keypress.enter="onChangeCheckedInFilter"
                      type="number"
                      hide-details
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </div>
    </div>
    <v-dialog v-model="addDialog" max-width="520px" v-if="addDialog" persistent>
      <v-card>
        <v-card-title class="headline primary-color">
          Save filter
        </v-card-title>
        <v-card-text>
          Your filter will be saved to be used in a subsequent search according
          to the characteristics of your needs.
        </v-card-text>
        <v-card-text>
          <v-text-field
            label="Title"
            dense
            outlined
            full-width
            v-model="newTitle"
            :messages="saveFilterMessage"
            @change="onChangeFilterMessage"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addDialog = !addDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="handleSave"
            :disabled="!newTitle"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="520px" v-if="updateDialog">
      <v-card>
        <v-card-title class="headline primary-color">
          Update filter
        </v-card-title>
        <v-card-text>
          Do you want update the existing filter?
        </v-card-text>
        <v-card-text>
          {{ newTitle }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="updateDialog = !updateDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="handleUpdate"
            :disabled="!newTitle"
            >Update</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadDialog"
      max-width="820px"
      v-if="loadDialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary-color">Load filter</v-card-title>
        <v-card-text>
          <div
            v-for="clientFilter in clientFilters"
            :key="clientFilter._id"
            class="hover-item d-flex justify-between mb-3"
            @click="selectFilter(clientFilter)"
          >
            {{ clientFilter.title }}
            <v-icon size="20" @click.stop="onDeleteFilter(clientFilter)"
              >mdi-delete</v-icon
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="loadDialog = !loadDialog"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="520px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this filter? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="handleDelete"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    id: String,
    onIntake: Function,
    onDeleted: Function,
    onClosePanel: Function,
  },
  data() {
    return {
      errorMessage: null,
      loading: false,
      snackbar: false,
      snackMessage: null,
      addDialog: false,
      updateDialog: false,
      loadDialog: false,
      deleteDialog: false,
      filters: [
        {
          label: "HOUSEHOLD",
          children: [
            { label: "Language" },
            { label: "Marital Status" },
            {
              label: "Internet",
            },
            {
              label: "Have transportation",
            },
            { label: "English proficiency" },
            { label: "Access to Food" },
            {
              label: "Meals you have challenges preparing",
            },
            {
              label: "Health rate",
            },
            {
              label: "Access to Heat",
            },
            {
              label: "Transportation",
            },
            {
              label: "Housing",
            },
            {
              label: "Housing type",
            },
            {
              label: "Food insecurity",
            },
            {
              label: "Financial insecurity",
            },
            {
              label: "Needed days in a month",
            },
            {
              label: "Needed months",
            },
            {
              label: "The number of people in the family",
            },
          ],
        },
        {
          label: "FAMILY MEMBER",
          children: [
            { label: "Name" },
            { label: "Age" },
            { label: "Gender" },
            { label: "Situation" },
            { label: "Relation" },
            { label: "School" },
            { label: "Medical" },
          ],
        },
        {
          label: "CHECKED IN",
          children: [
            { label: "Visited family" },
            { label: "Date" },
            { label: "Name" },
            { label: "Note" },
            { label: "Age" },
            { label: "Gender" },
            { label: "Situation" },
            { label: "Relation" },
            { label: "School" },
            // { label: "Medical" },
          ],
        },
      ],
      memberTag: null,
      checkedInTag: null,
      genderOptions: ["Male", "Female", "Both"],
      languageOptions: [
        "English",
        "Portuguese",
        "Spanish",
        "Russian",
        "Other",
        "N/A",
      ],
      relationOptions: [],
      situationOptions: [],
      maritalOptions: [],
      schoolOptions: [],
      englishProficiencyOptions: [
        "Fluent",
        "Very good",
        "Good",
        "Can speak a little",
        "None",
      ],
      accessFoodOptions: [
        "Insufficient to meet need",
        "Meet basic needs with some support",
        "Sufficient to meet need",
      ],
      mealsChallengeOptions: ["Breakfast", "Lunch", "Dinner"],
      healthRateOptions: ["Excellent", "Very good", "Good", "Fair", "Poor"],
      accessHeatOptions: [
        "No heat.. need help to access that",
        "Have resident with some assistance",
        "Able to heat resident.. no assistance needed",
      ],
      transportationOptions: [
        "Have regular access to car",
        "Have occasional access to car",
        "Have access to public transportation",
        "Have no access to transportation",
        "Other",
      ],
      housingOptions: [
        "Housing is trouble",
        "At risk of eviction/losing housing",
        "Homeless",
      ],
      housingTypeOptions: [
        "Unsubsidized",
        "Subsidized",
        "Shelter",
        "Friend/family",
        "Other",
      ],
      yesNoOptions: ["Yes", "No"],
      is_pregnant: false,
      min_age: "",
      max_age: "",
      gender: "",
      name: "",
      min_height: "",
      max_height: "",
      min_weight: "",
      max_weight: "",
      min_bmi: "",
      max_bmi: "",
      min_total_cholesterol: "",
      max_total_cholesterol: "",
      min_hdl: "",
      max_hdl: "",
      min_systolic: "",
      max_systolic: "",
      min_diastolic: "",
      max_diastolic: "",
      min_blood_sugar: "",
      max_blood_sugar: "",
      languages: [],
      situations: [],
      relations: [],
      maritals: [],
      english_proficiencies: [],
      access_to_foods: [],
      meals_challenges: [],
      health_rates: [],
      access_to_heats: [],
      transportations: [],
      housings: [],
      housing_types: [],
      internets: [],
      have_transportations: [],
      food_insecurities: [],
      financial_insecurities: [],
      min_day: "",
      max_day: "",
      min_month: "",
      max_month: "",
      min_total_family: "",
      max_total_family: "",
      schools: [],
      checkedInFromDateTimer: false,
      checkedInFromDate: null,
      checkedInToDateTimer: false,
      checkedInToDate: null,
      checked_in_situations: [],
      checked_in_relations: [],
      checked_in_schools: [],
      checked_in_name: "",
      checked_in_note: "",
      checked_in_from_date: "",
      checked_in_to_date: "",
      checked_in_min_age: "",
      checked_in_max_age: "",
      checked_in_gender: "",
      checked_in_situation: "",
      checked_in_relation: "",
      checked_in_school: "",
      checked_in_min_family_number: "",
      checked_in_max_family_number: "",
      // checked_in_school: "",
      filteredFamily: {},
      countData: {},
      filterOptions: {},
      newTitle: "",
      filterTitle: "",
      selected: null,
      saveFilterMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      clientFilters: "filter/getAllFilters",
    }),
  },
  methods: {
    ...mapActions({
      getValues: "variable/getValues",
      getFamilyFiltered: "intake/getFamilyFiltered",
      addFilter: "filter/addFilter",
      updateFilter: "filter/editFilter",
      deleteFilter: "filter/deleteFilter",
      fetchAllFilters: "filter/fetchAllFilters",
    }),
    onChangeFilter() {
      this.filterOptions = {
        ...this.filterOptions,
        language: this.languages.join(),
        marital: this.maritals.join(),
        english_proficiency: this.english_proficiencies.join(),
        meals_challenge: this.meals_challenges.join(),
        health_rate: this.health_rates.join(),
        access_to_food: this.access_to_foods.join(),
        access_to_heat: this.access_to_heats.join(),
        transportation: this.transportations.join(),
        housing: this.housings.join(),
        housing_type: this.housing_types.join(),
        internet: this.internets.join(),
        have_transportation: this.have_transportations.join(),
        food_insecurity: this.food_insecurities.join(),
        financial_insecurity: this.financial_insecurities.join(),
        min_day: this.min_day,
        max_day: this.max_day,
        min_month: this.min_month,
        max_month: this.max_month,
        min_total_family: this.min_total_family,
        max_total_family: this.max_total_family,
      };
      let cleanObj = {};
      Object.keys(this.filterOptions).forEach((val) => {
        const newVal = this.filterOptions[val];
        cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
      });
      this.filterOptions = cleanObj;
      console.log(this.filterOptions);
    },
    onChangeMemberFilter() {
      this.memberTag = "";
      if (this.name) {
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += this.name;
      }
      if (this.min_age || this.max_age) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_age && this.max_age) {
          this.memberTag += `Age ${this.min_age}-${this.max_age}`;
        } else if (this.min_age) {
          this.memberTag += `Age >${this.min_age}`;
        } else {
          this.memberTag += `Age <${this.max_age}`;
        }
      }
      if (this.gender && this.gender != "Both") {
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += this.gender;
      }
      if (this.relations && this.relations.length) {
        this.relations = this.relations.sort();
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += this.relations.join(", ");
      }
      if (this.situations && this.situations.length) {
        this.situations = this.situations.sort();
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += this.situations.join(", ");
      }
      if (this.schools && this.schools.length) {
        this.schools = this.schools.sort();
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += this.schools.join(", ");
      }
      if (this.is_pregnant) {
        this.memberTag += this.memberTag ? ", " : "";
        this.memberTag += "Is pregnant or nursing";
      }
      if (this.min_height || this.max_height) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_height && this.max_height) {
          this.memberTag += `Height ${this.min_height}-${this.max_height}`;
        } else if (this.min_height) {
          this.memberTag += `Height >${this.min_height}`;
        } else {
          this.memberTag += `Height <${this.max_height}`;
        }
      }
      if (this.min_weight || this.max_weight) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_weight && this.max_weight) {
          this.memberTag += `Weight ${this.min_weight}-${this.max_weight}`;
        } else if (this.min_weight) {
          this.memberTag += `Weight >${this.min_weight}`;
        } else {
          this.memberTag += `Weight <${this.max_weight}`;
        }
      }
      if (this.min_bmi || this.max_bmi) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_bmi && this.max_bmi) {
          this.memberTag += `BMI ${this.min_bmi}-${this.max_bmi}`;
        } else if (this.min_bmi) {
          this.memberTag += `BMI >${this.min_bmi}`;
        } else {
          this.memberTag += `BMI <${this.max_bmi}`;
        }
      }
      if (this.min_total_cholesterol || this.max_total_cholesterol) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_total_cholesterol && this.max_total_cholesterol) {
          this.memberTag += `Total Cholesterol ${this.min_total_cholesterol}-${this.max_total_cholesterol}`;
        } else if (this.min_total_cholesterol) {
          this.memberTag += `Total Cholesterol >${this.min_total_cholesterol}`;
        } else {
          this.memberTag += `Total Cholesterol <${this.max_total_cholesterol}`;
        }
      }
      if (this.min_hdl || this.max_hdl) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_hdl && this.max_hdl) {
          this.memberTag += `HDL ${this.min_hdl}-${this.max_hdl}`;
        } else if (this.min_hdl) {
          this.memberTag += `HDL >${this.min_hdl}`;
        } else {
          this.memberTag += `HDL <${this.max_hdl}`;
        }
      }
      if (this.min_systolic || this.max_systolic) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_systolic && this.max_systolic) {
          this.memberTag += `Systolic ${this.min_systolic}-${this.max_systolic}`;
        } else if (this.min_systolic) {
          this.memberTag += `Systolic >${this.min_systolic}`;
        } else {
          this.memberTag += `Systolic <${this.max_systolic}`;
        }
      }
      if (this.min_diastolic || this.max_diastolic) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_diastolic && this.max_diastolic) {
          this.memberTag += `Diastolic ${this.min_diastolic}-${this.max_diastolic}`;
        } else if (this.min_diastolic) {
          this.memberTag += `Diastolic >${this.min_diastolic}`;
        } else {
          this.memberTag += `Diastolic <${this.max_diastolic}`;
        }
      }
      if (this.min_blood_sugar || this.max_blood_sugar) {
        this.memberTag += this.memberTag ? ", " : "";
        if (this.min_blood_sugar && this.max_blood_sugar) {
          this.memberTag += `Blood Sugar ${this.min_blood_sugar}-${this.max_blood_sugar}`;
        } else if (this.min_blood_sugar) {
          this.memberTag += `Blood Sugar >${this.min_blood_sugar}`;
        } else {
          this.memberTag += `Blood Sugar <${this.max_blood_sugar}`;
        }
      }
    },
    onChangeCheckedInFilter() {
      this.checkedInTag = "";
      if (this.checked_in_name) {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += this.checked_in_name;
      }
      if (this.checked_in_note) {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += "Note: " + this.checked_in_note;
      }
      if (this.checked_in_min_age || this.checked_in_max_age) {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        if (this.checked_in_min_age && this.checked_in_max_age) {
          this.checkedInTag += `Checked In Age ${this.checked_in_min_age}-${this.checked_in_max_age}`;
        } else if (this.checked_in_min_age) {
          this.checkedInTag += `Checked In Age >${this.checked_in_min_age}`;
        } else {
          this.checkedInTag += `Checked In Age <${this.checked_in_max_age}`;
        }
      }
      if (
        this.checked_in_min_family_number ||
        this.checked_in_max_family_number
      ) {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        if (
          this.checked_in_min_family_number &&
          this.checked_in_max_family_number
        ) {
          this.checkedInTag += `Checked In Visitors: ${this.checked_in_min_family_number}-${this.checked_in_max_family_number}`;
        } else if (this.checked_in_min_family_number) {
          this.checkedInTag += `Checked In Visitors >${this.checked_in_min_family_number}`;
        } else {
          this.checkedInTag += `Checked In Visitors <${this.checked_in_max_family_number}`;
        }
      }

      if (this.checked_in_from_date || this.checked_in_to_date) {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        if (this.checked_in_from_date && this.checked_in_to_date) {
          this.checkedInTag += `Checked In From ${this.checked_in_from_date} to ${this.checked_in_to_date}`;
        } else if (this.checked_in_from_date) {
          this.checkedInTag += `Checked In From ${this.checked_in_from_date}`;
        } else {
          this.checkedInTag += `Checked In To ${this.checked_in_to_date}`;
        }
      }

      if (this.checked_in_gender && this.checked_in_gender != "Both") {
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += this.checked_in_gender;
      }
      if (this.checked_in_relations && this.checked_in_relations.length) {
        this.checked_in_relations = this.checked_in_relations.sort();
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += this.checked_in_relations.join(", ");
      }
      if (this.checked_in_situations && this.checked_in_situations.length) {
        this.checked_in_situations = this.checked_in_situations.sort();
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += this.checked_in_situations.join(", ");
      }
      if (this.checked_in_schools && this.checked_in_schools.length) {
        this.checked_in_schools = this.checked_in_schools.sort();
        this.checkedInTag += this.checkedInTag ? ", " : "";
        this.checkedInTag += this.checked_in_schools.join(", ");
      }
      // if (this.is_pregnant) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   this.memberTag += "Is pregnant or nursing";
      // }
      // if (this.min_height || this.max_height) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_height && this.max_height) {
      //     this.memberTag += `Height ${this.min_height}-${this.max_height}`;
      //   } else if (this.min_height) {
      //     this.memberTag += `Height >${this.min_height}`;
      //   } else {
      //     this.memberTag += `Height <${this.max_height}`;
      //   }
      // }
      // if (this.min_weight || this.max_weight) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_weight && this.max_weight) {
      //     this.memberTag += `Weight ${this.min_weight}-${this.max_weight}`;
      //   } else if (this.min_weight) {
      //     this.memberTag += `Weight >${this.min_weight}`;
      //   } else {
      //     this.memberTag += `Weight <${this.max_weight}`;
      //   }
      // }
      // if (this.min_bmi || this.max_bmi) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_bmi && this.max_bmi) {
      //     this.memberTag += `BMI ${this.min_bmi}-${this.max_bmi}`;
      //   } else if (this.min_bmi) {
      //     this.memberTag += `BMI >${this.min_bmi}`;
      //   } else {
      //     this.memberTag += `BMI <${this.max_bmi}`;
      //   }
      // }
      // if (this.min_total_cholesterol || this.max_total_cholesterol) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_total_cholesterol && this.max_total_cholesterol) {
      //     this.memberTag += `Total Cholesterol ${this.min_total_cholesterol}-${this.max_total_cholesterol}`;
      //   } else if (this.min_total_cholesterol) {
      //     this.memberTag += `Total Cholesterol >${this.min_total_cholesterol}`;
      //   } else {
      //     this.memberTag += `Total Cholesterol <${this.max_total_cholesterol}`;
      //   }
      // }
      // if (this.min_hdl || this.max_hdl) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_hdl && this.max_hdl) {
      //     this.memberTag += `HDL ${this.min_hdl}-${this.max_hdl}`;
      //   } else if (this.min_hdl) {
      //     this.memberTag += `HDL >${this.min_hdl}`;
      //   } else {
      //     this.memberTag += `HDL <${this.max_hdl}`;
      //   }
      // }
      // if (this.min_systolic || this.max_systolic) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_systolic && this.max_systolic) {
      //     this.memberTag += `Systolic ${this.min_systolic}-${this.max_systolic}`;
      //   } else if (this.min_systolic) {
      //     this.memberTag += `Systolic >${this.min_systolic}`;
      //   } else {
      //     this.memberTag += `Systolic <${this.max_systolic}`;
      //   }
      // }
      // if (this.min_diastolic || this.max_diastolic) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_diastolic && this.max_diastolic) {
      //     this.memberTag += `Diastolic ${this.min_diastolic}-${this.max_diastolic}`;
      //   } else if (this.min_diastolic) {
      //     this.memberTag += `Diastolic >${this.min_diastolic}`;
      //   } else {
      //     this.memberTag += `Diastolic <${this.max_diastolic}`;
      //   }
      // }
      // if (this.min_blood_sugar || this.max_blood_sugar) {
      //   this.memberTag += this.memberTag ? ", " : "";
      //   if (this.min_blood_sugar && this.max_blood_sugar) {
      //     this.memberTag += `Blood Sugar ${this.min_blood_sugar}-${this.max_blood_sugar}`;
      //   } else if (this.min_blood_sugar) {
      //     this.memberTag += `Blood Sugar >${this.min_blood_sugar}`;
      //   } else {
      //     this.memberTag += `Blood Sugar <${this.max_blood_sugar}`;
      //   }
      // }
    },
    clearMemberTag() {
      this.memberTag = null;
    },
    clearCheckedInTag() {
      this.checkedInTag = null;
    },
    onSaveFromDate(dateStr) {
      this.checkedInFromDateTimer = false;
      this.checked_in_from_date = dateStr;
      this.onChangeCheckedInFilter();
    },
    onSaveToDate(dateStr) {
      this.checkedInToDateTimer = false;
      this.checked_in_to_date = dateStr;
      this.onChangeCheckedInFilter();
    },
    addMemberTag() {
      var obj = {};
      // if (this.keyword) obj.key = this.keyword;
      if (this.name) obj.name = this.name;
      if (this.min_age) obj.min_age = this.min_age;
      if (this.max_age) obj.max_age = this.max_age;
      if (this.gender && this.gender != "Both") obj.gender = this.gender;
      if (this.relations && this.relations.length) {
        this.relations = this.relations.sort();
        obj.relation = this.relations.join();
      }
      if (this.situations && this.situations.length) {
        this.situations = this.situations.sort();
        obj.situation = this.situations.join();
      }
      if (this.schools && this.schools.length) {
        this.schools = this.schools.sort();
        obj.school = this.schools.join();
      }
      if (this.is_pregnant) obj.is_pregnant = this.is_pregnant;
      if (this.min_height) obj.min_height = this.min_height;
      if (this.max_height) obj.max_height = this.max_height;
      if (this.min_weight) obj.min_weight = this.min_weight;
      if (this.max_weight) obj.max_weight = this.max_weight;
      if (this.min_bmi) obj.min_bmi = this.min_bmi;
      if (this.max_bmi) obj.max_bmi = this.max_bmi;
      if (this.min_total_cholesterol)
        obj.min_total_cholesterol = this.min_total_cholesterol;
      if (this.max_total_cholesterol)
        obj.max_total_cholesterol = this.max_total_cholesterol;
      if (this.min_hdl) obj.min_hdl = this.min_hdl;
      if (this.max_hdl) obj.max_hdl = this.max_hdl;
      if (this.min_systolic) obj.min_systolic = this.min_systolic;
      if (this.max_systolic) obj.max_systolic = this.max_systolic;
      if (this.min_diastolic) obj.min_diastolic = this.min_diastolic;
      if (this.max_diastolic) obj.max_diastolic = this.max_diastolic;
      if (this.min_blood_sugar) obj.min_blood_sugar = this.min_blood_sugar;
      if (this.max_blood_sugar) obj.max_blood_sugar = this.max_blood_sugar;

      console.log(this.filterOptions.family);
      this.filterOptions = {
        ...this.filterOptions,
        family: this.filterOptions.family
          ? JSON.stringify({
              query: [
                ...new Set([
                  ...JSON.parse(this.filterOptions.family).query,
                  obj,
                ]),
              ],
            })
          : JSON.stringify({ query: [obj] }),
      };
      this.memberTag = null;
      this.min_age = "";
      this.max_age = "";
      this.gender = "";
      this.situations = [];
      this.relations = [];
      this.schools = [];
      this.min_height = "";
      this.max_height = "";
      this.min_weight = "";
      this.max_weight = "";
      this.min_bmi = "";
      this.max_bmi = "";
      this.min_total_cholesterol = "";
      this.max_total_cholesterol = "";
      this.min_hdl = "";
      this.max_hdl = "";
      this.min_systolic = "";
      this.max_systolic = "";
      this.min_diastolic = "";
      this.max_diastolic = "";
      this.min_blood_sugar = "";
      this.max_blood_sugar = "";
    },
    addCheckedInTag() {
      var obj = {};
      // if (this.keyword) obj.key = this.keyword;
      if (this.checked_in_name) obj.checked_in_name = this.checked_in_name;
      if (this.checked_in_note) obj.checked_in_note = this.checked_in_note;
      if (this.checked_in_min_family_number)
        obj.checked_in_min_family_number = this.checked_in_min_family_number;
      if (this.checked_in_max_family_number)
        obj.checked_in_max_family_number = this.checked_in_max_family_number;
      if (this.checked_in_from_date)
        obj.checked_in_from_date = this.checked_in_from_date;
      if (this.checked_in_to_date)
        obj.checked_in_to_date = this.checked_in_to_date;
      if (this.checked_in_min_age)
        obj.checked_in_min_age = this.checked_in_min_age;
      if (this.checked_in_max_age)
        obj.checked_in_max_age = this.checked_in_max_age;
      if (this.checked_in_gender && this.checked_in_max_gender != "Both")
        obj.checked_in_max_gender = this.checked_in_max_gender;
      if (this.checked_in_relations && this.checked_in_relations.length) {
        this.checked_in_relations = this.checked_in_relations.sort();
        obj.checked_in_relation = this.checked_in_relations.join();
      }
      if (this.checked_in_situations && this.checked_in_situations.length) {
        this.checked_in_situations = this.checked_in_situations.sort();
        obj.checked_in_situation = this.checked_in_situations.join();
      }
      if (this.checked_in_schools && this.checked_in_schools.length) {
        this.checked_in_schools = this.checked_in_schools.sort();
        obj.checked_in_school = this.checked_in_schools.join();
      }
      // if (this.is_pregnant) obj.is_pregnant = this.is_pregnant;
      // if (this.min_height) obj.min_height = this.min_height;
      // if (this.max_height) obj.max_height = this.max_height;
      // if (this.min_weight) obj.min_weight = this.min_weight;
      // if (this.max_weight) obj.max_weight = this.max_weight;
      // if (this.min_bmi) obj.min_bmi = this.min_bmi;
      // if (this.max_bmi) obj.max_bmi = this.max_bmi;
      // if (this.min_total_cholesterol)
      //   obj.min_total_cholesterol = this.min_total_cholesterol;
      // if (this.max_total_cholesterol)
      //   obj.max_total_cholesterol = this.max_total_cholesterol;
      // if (this.min_hdl) obj.min_hdl = this.min_hdl;
      // if (this.max_hdl) obj.max_hdl = this.max_hdl;
      // if (this.min_systolic) obj.min_systolic = this.min_systolic;
      // if (this.max_systolic) obj.max_systolic = this.max_systolic;
      // if (this.min_diastolic) obj.min_diastolic = this.min_diastolic;
      // if (this.max_diastolic) obj.max_diastolic = this.max_diastolic;
      // if (this.min_blood_sugar) obj.min_blood_sugar = this.min_blood_sugar;
      // if (this.max_blood_sugar) obj.max_blood_sugar = this.max_blood_sugar;

      console.log(this.filterOptions.checked_in);
      this.filterOptions = {
        ...this.filterOptions,
        checked_in: this.filterOptions.checked_in
          ? JSON.stringify({
              query: [
                ...new Set([
                  ...JSON.parse(this.filterOptions.checked_in).query,
                  obj,
                ]),
              ],
            })
          : JSON.stringify({ query: [obj] }),
      };
      this.checkedInTag = null;
      this.checked_in_min_age = "";
      this.checked_in_max_age = "";
      this.checked_in_gender = "";
      this.checked_in_situations = [];
      this.checked_in_relations = [];
      this.checked_in_schools = [];
      this.checked_in_from_date = "";
      this.checked_in_to_date = "";
      this.checked_in_min_family_number = "";
      this.checked_in_max_family_number = "";
      this.checked_in_name = "";
      this.checked_in_note = "";
      // this.min_height = "";
      // this.max_height = "";
      // this.min_weight = "";
      // this.max_weight = "";
      // this.min_bmi = "";
      // this.max_bmi = "";
      // this.min_total_cholesterol = "";
      // this.max_total_cholesterol = "";
      // this.min_hdl = "";
      // this.max_hdl = "";
      // this.min_systolic = "";
      // this.max_systolic = "";
      // this.min_diastolic = "";
      // this.max_diastolic = "";
      // this.min_blood_sugar = "";
      // this.max_blood_sugar = "";
    },
    filterUpdateWithRoute() {
      this.filterOptions = { ...this.$route.query };
      this.isNew =
        this.filterOptions.new == "true" || this.filterOptions.new == true;
      if (this.filterOptions.language)
        this.languages = this.filterOptions.language.split(",");
      else this.languages = [];
      if (this.filterOptions.marital)
        this.maritals = this.filterOptions.marital.split(",");
      else this.maritals = [];
      if (this.filterOptions.english_proficiency)
        this.english_proficiencies = this.filterOptions.english_proficiency.split(
          ","
        );
      else this.english_proficiencies = [];
      if (this.filterOptions.access_to_food)
        this.access_to_foods = this.filterOptions.access_to_food.split(",");
      else this.access_to_foods = [];
      if (this.filterOptions.meals_challenge)
        this.meals_challenges = this.filterOptions.meals_challenge.split(",");
      else this.meals_challenges = [];
      if (this.filterOptions.health_rate)
        this.health_rates = this.filterOptions.health_rate.split(",");
      else this.health_rates = [];
      if (this.filterOptions.access_to_heat)
        this.access_to_heats = this.filterOptions.access_to_heat.split(",");
      else this.access_to_heats = [];
      if (this.filterOptions.transportation)
        this.transportations = this.filterOptions.transportation.split(",");
      else this.transportations = [];
      if (this.filterOptions.housing)
        this.housings = this.filterOptions.housing.split(",");
      else this.housings = [];
      if (this.filterOptions.housing_type)
        this.housing_types = this.filterOptions.housing_type.split(",");
      else this.housing_types = [];

      if (this.filterOptions.interests)
        this.interests = this.filterOptions.interest.split(",");
      else this.interests = [];
      if (this.filterOptions.have_transportation)
        this.have_transportations = this.filterOptions.have_transportation.split(
          ","
        );
      else this.have_transportations = [];

      if (this.filterOptions.food_insecurity)
        this.food_insecurities = this.filterOptions.food_insecurity.split(",");
      else this.food_insecurities = [];

      if (this.filterOptions.financial_insecurity)
        this.financial_insecurities = this.filterOptions.financial_insecurity.split(
          ","
        );
      else this.financial_insecurities = [];

      this.min_day = this.filterOptions.min_day;
      this.max_day = this.filterOptions.max_day;
      this.min_month = this.filterOptions.min_month;
      this.max_month = this.filterOptions.max_month;
      this.min_total_family = this.filterOptions.min_total_family;
      this.max_total_family = this.filterOptions.max_total_family;
    },
    onSaveFilter() {
      let param = {};
      Object.entries(this.$route.query).map(([key, value]) => {
        if (value) {
          param[`${key}`] = value;
        }
      });
      let query = JSON.stringify(param);
      if (query == "{}") return;
      this.addDialog = true;
      this.newTitle = this.filterTitle;
      this.saveFilterMessage = "";
    },
    onLoadFilter() {
      this.loadDialog = true;
    },
    handleSave() {
      this.saveFilterMessage = "";
      if (!this.newTitle) return;
      let param = {};
      Object.entries(this.$route.query).map(([key, value]) => {
        if (value) {
          param[`${key}`] = value;
        }
      });
      let query = JSON.stringify(param);
      this.loading = true;
      this.addFilter({ title: this.newTitle, query })
        .then(() => {
          this.loading = false;
          this.addDialog = false;
          this.filterTitle = this.newTitle;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message == "Filter already exist") {
            this.updateDialog = true;
            return;
          }
          this.saveFilterMessage = error.response.data.message;
          console.log(error.response.data.message);
        });
    },
    handleUpdate() {
      this.saveFilterMessage = "";
      if (!this.newTitle) return;
      let param = {};
      Object.entries(this.$route.query).map(([key, value]) => {
        if (value) {
          param[`${key}`] = value;
        }
      });
      let query = JSON.stringify(param);
      this.loading = true;
      this.updateFilter({ title: this.newTitle, query })
        .then(() => {
          this.loading = false;
          this.addDialog = false;
          this.updateDialog = false;
          this.filterTitle = this.newTitle;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar = true;
          this.snackMessage = error.response.data.message;
          console.log(error.response.data.message);
        });
    },
    selectFilter(clientFilter) {
      this.loadDialog = false;
      const query = JSON.parse(clientFilter.query);
      this.$router.replace({ query });
      this.filterTitle = clientFilter.title;
    },
    onDeleteFilter(clientFilter) {
      this.selected = clientFilter;
      this.deleteDialog = true;
    },
    handleDelete() {
      this.loading = true;
      this.deleteFilter({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    onChangeFilterMessage() {
      this.saveFilterMessage = null;
    },
  },
  watch: {
    filterOptions(newValue) {
      if (
        (newValue || Object.keys(newValue).length) &&
        Object.getPrototypeOf(newValue) === Object.prototype
      ) {
        if (!this.$route.query) {
          let cleanObj = {};
          Object.keys(newValue).forEach((val) => {
            const newVal = newValue[val];
            cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
          });
          this.$router.replace({
            query: { ...cleanObj },
          });
        } else {
          newValue = { ...newValue };
          let cleanObj = {};
          Object.keys(newValue).forEach((val) => {
            const newVal = newValue[val];
            cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
          });
          if (JSON.stringify(cleanObj) != JSON.stringify(this.$route.query)) {
            this.$router.replace({
              query: { ...cleanObj },
            });
          }
        }
      } else if (
        this.$route.query &&
        Object.keys(this.$route.query).length === 0 &&
        Object.getPrototypeOf(this.$route.query) === Object.prototype
      ) {
        this.$router.replace({
          query: {},
        });
      }
    },
    "$route.query": function() {
      this.filterUpdateWithRoute();
    },
  },
  mounted() {
    this.getValues({ title: "Relation" })
      .then((res) => {
        this.relationOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getValues({ title: "Situation Types" })
      .then((res) => {
        this.situationOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getValues({ title: "Marital Types" })
      .then((res) => {
        this.maritalOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getValues({ title: "School Names" })
      .then((res) => {
        this.schoolOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    this.filterUpdateWithRoute();
    this.fetchAllFilters().catch((error) =>
      console.log(error.response.data.message)
    );
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 370px;
  min-width: 370px;
  width: 370px;
  background: white;
  z-index: 7;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

.left-section >>> .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}

.left-section >>> .v-chip {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.left-section >>> .v-chip__content {
  white-space: initial;
}
/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.item-label {
  font-family: "Poppins-SemiBold";
  font-size: 12px;
  color: #472583;
}
.item-value {
  font-family: "Poppins-Regular";
  font-size: 12px;
  color: #423f63;
  word-break: break-word;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.client_title {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
div >>> .v-date-picker-table {
  height: auto;
}
div >>> .v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}
</style>
